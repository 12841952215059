import React from 'react';

import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Button from '../components/Button/Button';

import { Formik } from 'formik';
import firebase from 'gatsby-plugin-firebase';
import axios from 'axios';

const Register = () => {
  const [submit, setSubmit] = React.useState({ done: false, message: '' });
  const closedAt = 1626541200000;

  return (
    <Layout>
      <SEO title="Register" />
      <Formik
        initialValues={{
          teamName: '',
          leaderName: '',
          leaderEmail: '',
          phoneNumber: undefined,
          member1Name: '',
          member1Email: '',
          member2Name: '',
          member2Email: '',
          file: undefined,
        }}
        validate={(values) => {
          const errors = {};
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.leaderEmail)) {
            errors.leaderEmail = 'Incorrect email format';
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.member1Email)) {
            errors.member1Email = 'Incorrect email format';
          }
          if (
            values.member2Email &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.member2Email)
          ) {
            errors.member2Email = 'Incorrect email format';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const file = {};
          const fileDbRef = firebase
            .storage()
            .ref(`srexge/competition/${values.teamName}-${values.phoneNumber}`);
          setSubmit({ done: true, message: 'Sending...' });
          fileDbRef
            .child(`${values.file.name}`)
            .put(values.file)
            .then((snapshot) =>
              snapshot.ref.getDownloadURL().then((downloadUrl) => {
                file['file'] = downloadUrl;
              })
            )
            .then(() => {
              firebase
                .database()
                .ref(`/competition/${values.teamName}-${values.phoneNumber}`)
                .set({
                  teamName: values.teamName,
                  leaderName: values.leaderName,
                  leaderEmail: values.leaderEmail,
                  phoneNumber: values.phoneNumber,
                  member1Name: values.member1Name,
                  member1Email: values.member1Email,
                  member2Name: values.member2Name,
                  member2Email: values.member2Email,
                  ...file,
                })
                .then(() => {
                  axios
                    .post(
                      'https://us-central1-sre-website-a43e8.cloudfunctions.net/sendEmailOnGeYicRegistration',
                      {
                        email: values.leaderEmail,
                        teamName: values.teamName,
                      }
                    )
                    .then(() => {
                      setSubmitting(false);
                      setSubmit({ done: true, message: 'Success!' });
                    })
                    .catch((err) => {
                      console.log(err);
                      setSubmitting(false);
                      setSubmit({
                        done: true,
                        message: 'Failed, Please refresh this page and try again',
                      });
                    });
                })
                .catch((err) => {
                  console.log(err);
                  setSubmitting(false);
                  setSubmit({
                    done: true,
                    message: 'Failed, Please refresh this page and try again',
                  });
                });
            })
            .catch((err) => console.log(err));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-center pt-8 pb-0 lg:pb-8">
              <div className="flex flex-col mx-8 lg:mx-auto container">
                <h1 className="mb-4 xl:mb-12 font-bold text-4xl xl:text-5xl text-center">
                  GE x SRE
                  <br />
                  Youth Idea Competition 2021
                </h1>
                {new Date() < closedAt ? (
                  <>
                    <div className="mb-4">
                      <h1 className="text-2xl font-bold">Team Identity</h1>
                      <hr className="border-black mb-3" />
                      <label htmlFor="teamName" className="text-lg lg:text-xl">
                        Team Name:
                      </label>
                      <input
                        type="text"
                        name="teamName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.teamName}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="your team name"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.teamName && touched.teamName && errors.teamName}
                      </p>
                    </div>
                    <div className="mb-4">
                      <h1 className="text-2xl font-bold">Team Leader Identity</h1>
                      <hr className="border-black mb-3" />
                      <label htmlFor="leaderName" className="text-lg lg:text-xl">
                        Lead Name:
                      </label>
                      <input
                        type="text"
                        name="leaderName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.leaderName}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="team leader name"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.leaderName && touched.leaderName && errors.leaderName}
                      </p>
                      <label htmlFor="leaderEmail" className="text-lg lg:text-xl">
                        Email Address:
                      </label>
                      <input
                        type="email"
                        name="leaderEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.leaderEmail}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="johndoe@example.com"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.leaderEmail && touched.leaderEmail && errors.leaderEmail}
                      </p>
                      <label htmlFor="phoneNumber" className="text-lg lg:text-xl">
                        Telp. Number/<i>Whatsapp</i>:
                      </label>
                      <input
                        type="number"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="08123456789"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                      </p>
                    </div>
                    <div className="mb-4">
                      <h1 className="text-2xl font-bold">1st Member Identity</h1>
                      <hr className="border-black mb-3" />
                      <label htmlFor="member1Name" className="text-lg lg:text-xl">
                        Name:
                      </label>
                      <input
                        type="text"
                        name="member1Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.member1Name}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="first member name"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.member1Name && touched.member1Name && errors.member1Name}
                      </p>
                      <label htmlFor="member1Email" className="text-lg lg:text-xl">
                        Email Address:
                      </label>
                      <input
                        type="email"
                        name="member1Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.member1Email}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="johndoe@example.com"
                        required={true}
                      />
                      <p className="text-red-500 mb-2">
                        {errors.member1Email && touched.member1Email && errors.member1Email}
                      </p>
                    </div>
                    <div className="mb-4">
                      <h1 className="text-2xl font-bold">2nd Member Identity (Optional)</h1>
                      <hr className="border-black mb-3" />
                      <label htmlFor="member2Name" className="text-lg lg:text-xl">
                        Name:
                      </label>
                      <input
                        type="text"
                        name="member2Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.member2Name}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="second member name"
                      />
                      <p className="text-red-500 mb-2">
                        {errors.member2Name && touched.member2Name && errors.member2Name}
                      </p>
                      <label htmlFor="member2Email" className="text-lg lg:text-xl">
                        Email Address:
                      </label>
                      <input
                        type="email"
                        name="member2Email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.member2Email}
                        className="my-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                        placeholder="johndoe@example.com"
                      />
                      <p className="text-red-500 mb-2">
                        {errors.member2Email && touched.member2Email && errors.member2Email}
                      </p>
                    </div>
                    <div className="mb-4">
                      <h1 className="text-2xl font-bold">Submission File</h1>
                      <hr className="border-black mb-3" />
                      <label htmlFor="file" className="text-lg lg:text-xl">
                        ZIP or .RAR form files with a maximum size of 10 Mb:
                        <br />
                      </label>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        onChange={(event) => {
                          setFieldValue('file', event.currentTarget.files[0]);
                        }}
                        className="my-2 mb-6"
                        required={true}
                      />
                    </div>
                    <div className="my-6 flex justify-end">
                      <p className="flex justify-center items-center text-lg mr-4 font-bold">
                        {submit.message || ' '}
                      </p>
                      <Button
                        type="submit"
                        className="rounded-full"
                        disabled={isSubmitting || submit.done}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                ) : (<div className="w-full flex justify-center items-center" style={{ minHeight: '30vh' }}><p className="text-xl font-bold">Registration Closed</p></div>)}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Layout>
  );
};

export default Register;
